import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Card, CardHeader, CardContent } from "./components/ui/card";
import { useTheme } from './components/theme-provider';

const COLORS = Array.from({ length: 20 }, (_, i) => `hsl(var(--chart-${i + 1}))`);

const TopTracksChart = ({ data }) => {
  const { theme } = useTheme();

  const formatEuro = (value) => {
    return new Intl.NumberFormat('en-US', { 
      style: 'currency', 
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };

  const formatNumber = (value) => {
    return new Intl.NumberFormat('en-US').format(value);
  };

  return (
    <Card style={{ borderRadius: 'var(--radius)' }} className="w-full bg-background text-foreground">
      <CardHeader>
        <h2 className="text-2xl font-bold text-primary">Top Performing Tracks</h2>
      </CardHeader>
      <CardContent>
        <ResponsiveContainer width="100%" height={450}>
          <BarChart
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 100,
            }}
            className="border-border"
          >
            <CartesianGrid strokeDasharray="3 3" stroke="var(--border)" />
            <XAxis 
              dataKey="name" 
              angle={-45} 
              textAnchor="end" 
              height={80} 
              interval={0} 
              tick={{fontSize: 12, fill: 'var(--foreground)'}}
            />
            <YAxis 
              yAxisId="left" 
              orientation="left" 
              stroke="var(--primary)" 
              tickFormatter={formatEuro} 
            />
            <YAxis 
              yAxisId="right" 
              orientation="right" 
              stroke="var(--accent)" 
              tickFormatter={formatNumber} 
            />
            <Tooltip 
              formatter={(value, name) => {
                return name === "Revenue (€)" ? formatEuro(value) : formatNumber(value);
              }}
            />
            <Legend 
              verticalAlign="top" 
              height={36}
              wrapperStyle={{ color: 'var(--foreground)' }}
            />
            <Bar 
              yAxisId="left" 
              dataKey="revenue" 
              fill={COLORS[0]}
              name="Revenue (€)"
            />
            <Bar 
              yAxisId="right" 
              dataKey="streams" 
              fill={COLORS[1]}
              name="Streams"
            />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default TopTracksChart;