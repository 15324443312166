import React, { useState } from 'react';
import { parse } from 'papaparse';
import { Card, CardHeader, CardContent } from "./components/ui/card"
import { Button } from "./components/ui/button"
import { Alert, AlertDescription, AlertTitle } from "./components/ui/alert"
import { Input } from "./components/ui/input"
import { Upload } from "lucide-react"
import { Badge } from "./components/ui/badge"

const DataUpload = ({ onDataProcessed }) => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isProcessed, setIsProcessed] = useState(false);
  const [dateRange, setDateRange] = useState(null);

  const processData = (results) => {
    let minDate = new Date();
    let maxDate = new Date(0);

    const processedData = results.reduce((acc, fileData) => {
      const data = fileData.data;
      
      data.forEach(row => {
        const artist = row['Artist Name'];
        const revenueString = (row['Net Revenue'] || '').toString();
        const revenue = parseFloat(revenueString.replace(',', '.'));
        const streams = parseInt(row['Quantity'], 10);
        const service = row['Platform'];
        const usage = row['Sales Type'];
        const date = new Date(row['Reporting month']);
        const track = row['Track title'];
        const release = row['Release title'];

        // Update min and max dates
        if (date < minDate) minDate = date;
        if (date > maxDate) maxDate = date;

        // Process artist data
        if (!acc.artistData[artist]) {
          acc.artistData[artist] = { name: artist, revenue: 0, streams: 0, trackCount: 0, tracks: {} };
        }
        acc.artistData[artist].revenue += isNaN(revenue) ? 0 : revenue;
        acc.artistData[artist].streams += isNaN(streams) ? 0 : streams;
        
        // Track count and top track
        if (!acc.artistData[artist].tracks[track]) {
          acc.artistData[artist].trackCount++;
          acc.artistData[artist].tracks[track] = { revenue: 0, streams: 0 };
        }
        acc.artistData[artist].tracks[track].revenue += isNaN(revenue) ? 0 : revenue;
        acc.artistData[artist].tracks[track].streams += isNaN(streams) ? 0 : streams;

        // Process track data
        const trackKey = `${artist}-${track}`;
        if (!acc.trackData[trackKey]) {
          acc.trackData[trackKey] = { name: track, artist, release, revenue: 0, streams: 0, date };
        }
        acc.trackData[trackKey].revenue += isNaN(revenue) ? 0 : revenue;
        acc.trackData[trackKey].streams += isNaN(streams) ? 0 : streams;
        if (date > acc.trackData[trackKey].date) {
          acc.trackData[trackKey].date = date;
        }

        // Process service data
        const serviceKey = `${artist}-${service}`;
        if (!acc.serviceData[serviceKey]) {
          acc.serviceData[serviceKey] = { name: service, artist, revenue: 0, quantity: 0, date };
        }
        acc.serviceData[serviceKey].revenue += isNaN(revenue) ? 0 : revenue;
        acc.serviceData[serviceKey].quantity += isNaN(streams) ? 0 : streams;
        if (date > acc.serviceData[serviceKey].date) {
          acc.serviceData[serviceKey].date = date;
        }

        // Process usage data
        const usageKey = `${artist}-${usage}`;
        if (!acc.usageData[usageKey]) {
          acc.usageData[usageKey] = { name: usage, artist, revenue: 0, quantity: 0, date };
        }
        acc.usageData[usageKey].revenue += isNaN(revenue) ? 0 : revenue;
        acc.usageData[usageKey].quantity += isNaN(streams) ? 0 : streams;
        if (date > acc.usageData[usageKey].date) {
          acc.usageData[usageKey].date = date;
        }
      });

      return acc;
    }, { artistData: {}, trackData: {}, serviceData: {}, usageData: {} });

    // Convert to arrays and sort
    const artistData = Object.values(processedData.artistData).map(artist => {
      const topTrack = Object.entries(artist.tracks).reduce((a, b) => a[1].revenue > b[1].revenue ? a : b)[0];
      return { ...artist, topTrack };
    }).sort((a, b) => b.revenue - a.revenue);
    const trackData = Object.values(processedData.trackData).sort((a, b) => b.revenue - a.revenue);
    const serviceData = Object.values(processedData.serviceData).sort((a, b) => b.revenue - a.revenue);
    const usageData = Object.values(processedData.usageData).sort((a, b) => b.revenue - a.revenue);

    // Calculate date range
    const getQuarter = (date) => Math.floor((date.getMonth() + 3) / 3);
    const formatDateRange = (start, end) => {
      return `Q${getQuarter(start)} ${start.getFullYear()} - Q${getQuarter(end)} ${end.getFullYear()}`;
    };
    const newDateRange = formatDateRange(minDate, maxDate);
    setDateRange(newDateRange);

    onDataProcessed({ artistData, trackData, serviceData, usageData, dateRange: newDateRange });
  };

  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
    setError(null);
    setIsProcessed(false);
  };

  const handleUpload = () => {
    if (files.length === 0) {
      setError('Please select at least one file to upload.');
      return;
    }

    setIsLoading(true);
    setError(null);

    const parsePromises = files.map(file => 
      new Promise((resolve, reject) => {
        parse(file, {
          complete: (results) => resolve(results),
          header: true,
          error: (error) => reject(error)
        });
      })
    );

    Promise.all(parsePromises)
      .then(results => {
        processData(results);
        setIsProcessed(true);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        setError('Error parsing files: ' + error.message);
      });
  };

  const handleReset = () => {
    setFiles([]);
    setIsProcessed(false);
    setError(null);
  };

  return (
    <Card className="w-full max-w-md mx-auto">
      <CardHeader>
        <h2 className="text-2xl font-semibold">{isProcessed ? 'Data Uploaded' : 'Upload CSV Data'}</h2>
        {dateRange && (
          <Badge 
            variant="outline" 
            className="mt-2 text-sm font-medium border-green-500 text-green-700 bg-green-50 w-full justify-center"
          >
            {dateRange}
          </Badge>
        )}
      </CardHeader>
      <CardContent>
        {!isProcessed ? (
          <div className="space-y-4">
            <div className="flex items-center justify-center w-full">
              <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-dashed rounded-lg cursor-pointer bg-muted hover:bg-muted/50">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <Upload className="w-10 h-10 mb-3 text-muted-foreground" />
                  <p className="mb-2 text-sm text-muted-foreground"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                  <p className="text-xs text-muted-foreground">CSV files (MAX. 10MB each)</p>
                </div>
                <Input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  onChange={handleFileChange}
                  accept=".csv"
                  multiple
                />
              </label>
            </div>
            {files.length > 0 && (
              <p className="text-sm text-muted-foreground">Selected files: {files.map(f => f.name).join(', ')}</p>
            )}
            <Button 
              onClick={handleUpload} 
              disabled={isLoading} 
              className="w-full"
            >
              {isLoading ? 'Processing...' : 'Upload and Process'}
            </Button>
            {error && (
              <Alert variant="destructive">
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
          </div>
        ) : (
          <div className="text-center">
            <p className="text-green-600 mb-4">Data processed successfully!</p>
            <Button onClick={handleReset} variant="outline">
              Upload More Files
            </Button>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default DataUpload;