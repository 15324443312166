import React, { useState, useContext } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useTheme } from './components/theme-provider';

const COLORS = Array.from({ length: 20 }, (_, i) => `hsl(var(--chart-${i + 1}))`);

const formatRevenue = (value) => {
  if (value >= 1000000) return `€${(value / 1000000).toFixed(2).replace('.', ',')}M`;
  if (value >= 1000) return `€${(value / 1000).toFixed(2)}K`;
  return `€${value.toFixed(2)}`;
};

const ArtistBreakdown = ({ data }) => {
  const { theme } = useTheme();
  const [expandedArtist, setExpandedArtist] = useState(null);

  const totalRevenue = data.reduce((sum, item) => sum + item.revenue, 0);

  const formattedData = data.map(item => ({
    ...item,
    formattedRevenue: formatRevenue(item.revenue),
    percentage: ((item.revenue / totalRevenue) * 100).toFixed(2)
  }));

  return (
    <>
      <div className="h-[400px] w-full">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={formattedData}
              dataKey="revenue"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={150}
              label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
              labelLine={false}
            >
              {formattedData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip formatter={(value) => formatRevenue(value)} />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="overflow-x-auto mt-8">
        <table className="w-full text-sm text-left">
          <thead className="text-xs uppercase bg-muted">
            <tr>
              <th scope="col" className="px-6 py-3 text-muted-foreground">Artist</th>
              <th scope="col" className="px-6 py-3 text-right text-muted-foreground">Revenue</th>
              <th scope="col" className="px-6 py-3 text-right text-muted-foreground">% of Total</th>
              <th scope="col" className="px-6 py-3 text-center text-muted-foreground">Details</th>
            </tr>
          </thead>
          <tbody>
            {formattedData.map((artist, index) => (
              <React.Fragment key={artist.name}>
                <tr className="bg-background border-b hover:bg-muted/50">
                  <th scope="row" className="px-6 py-4 font-medium text-foreground whitespace-nowrap">
                    {artist.name}
                  </th>
                  <td className="px-6 py-4 text-right">{artist.formattedRevenue}</td>
                  <td className="px-6 py-4 text-right">{artist.percentage}%</td>
                  <td className="px-6 py-4 text-center">
                    <button
                      onClick={() => setExpandedArtist(expandedArtist === artist.name ? null : artist.name)}
                      className="text-blue-600 hover:text-blue-900"
                    >
                      {expandedArtist === artist.name ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                    </button>
                  </td>
                </tr>
                {expandedArtist === artist.name && (
                  <tr className="bg-gray-50">
                    <td colSpan="4" className="px-6 py-4">
                      <div className="text-sm text-gray-700 space-y-2">
                        <p><span className="font-semibold">Number of tracks:</span> {artist.trackCount}</p>
                        <p><span className="font-semibold">Top track:</span> {artist.topTrack}</p>
                        <p><span className="font-semibold">Average revenue per track:</span> {formatRevenue(artist.revenue / artist.trackCount)}</p>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ArtistBreakdown;