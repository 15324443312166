import React from 'react';
import { Card, CardHeader, CardContent } from "./components/ui/card"
import SummaryCards from './SummaryCards';
import TopTracksChart from './TopTracksChart';
import ArtistBreakdown from './ArtistBreakdown';

const Dashboard = ({ data }) => {
  // Sort tracks by revenue in descending order and take top 10
  const topTracks = data.trackData
    .sort((a, b) => b.revenue - a.revenue)
    .slice(0, 10);

  return (
    <div className="space-y-6 bg-background text-foreground">
      <h1 className="text-3xl font-bold">0108 SLATAN Dashboard</h1>
      
      <SummaryCards data={data} />
      
      <TopTracksChart data={topTracks} />

      <Card className="bg-card text-card-foreground">
        <CardHeader>
          <h2 className="text-xl font-semibold">Artist Revenue Breakdown</h2>
        </CardHeader>
        <CardContent>
          <ArtistBreakdown data={data.artistData} />
        </CardContent>
      </Card>
    </div>
  );
};

export default Dashboard;