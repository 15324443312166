import React, { useState } from 'react';
import { ThemeProvider } from "./components/theme-provider"
import Dashboard from './Dashboard';
import DataUpload from './DataUpload';
import ReportGenerator from './ReportGenerator';
import { exportToPDF } from './pdfExport';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./components/ui/select"
import { Button } from "./components/ui/button"

const App = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [selectedArtist, setSelectedArtist] = useState(null);

  const handleDataProcessed = (data) => {
    setDashboardData(data);
    if (data.artistData.length > 0) {
      setSelectedArtist(data.artistData[0].name);
    }
  };

  const handleExportPDF = () => {
    const element = document.getElementById('dashboard-content');
    if (element) {
      exportToPDF(element, `${selectedArtist}_Dashboard.pdf`);
    }
  };

  return (
    <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
      <div className="container mx-auto px-4 py-8 max-w-7xl">
        <div className="space-y-8">
          <DataUpload onDataProcessed={handleDataProcessed} />
          {dashboardData && (
            <div id="dashboard-content" className="space-y-8">
              <Dashboard data={dashboardData} />
              <div className="bg-card p-4 rounded-lg shadow-sm">
                <label htmlFor="artist-select" className="block text-sm font-medium mb-2">
                  Select Artist for Report:
                </label>
                <Select onValueChange={setSelectedArtist} value={selectedArtist}>
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select an artist" />
                  </SelectTrigger>
                  <SelectContent>
                    {dashboardData.artistData.map(artist => (
                      <SelectItem key={artist.name} value={artist.name}>{artist.name}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <ReportGenerator data={dashboardData} selectedArtist={selectedArtist} />
            </div>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default App;