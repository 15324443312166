import React from 'react';
import { Card, CardHeader, CardContent } from './components/ui/card';

const SummaryCards = ({ data }) => {
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };

  const formatNumber = (value) => {
    return new Intl.NumberFormat('en-US').format(value);
  };

  const totalRevenue = data.artistData.reduce((sum, item) => sum + item.revenue, 0);
  const totalStreams = data.artistData.reduce((sum, item) => sum + item.streams, 0);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
      <Card className="overflow-hidden">
        <CardHeader className="bg-muted p-4">
          <h3 className="text-lg font-semibold text-foreground">Total Revenue</h3>
        </CardHeader>
        <CardContent className="p-4">
          <p className="text-3xl font-bold text-foreground">{formatCurrency(totalRevenue)}</p>
        </CardContent>
      </Card>
      <Card className="overflow-hidden">
        <CardHeader className="bg-gray-50 p-4">
          <h3 className="text-lg font-semibold">Total Streams</h3>
        </CardHeader>
        <CardContent className="p-4">
          <p className="text-3xl font-bold">{formatNumber(totalStreams)}</p>
        </CardContent>
      </Card>
    </div>
  );
};

export default SummaryCards;